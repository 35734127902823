import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useEffect } from 'react';
import AppPageContainer from '../../../components/AppPageContainer';
import ToolBar from '../../../components/ToolBar';
import amex from '../../../images/cards/amex.png';
import discover from '../../../images/cards/discover.png';
import visa from '../../../images/cards/visa.png';
import mastercard from '../../../images/cards/mastercard.png';
import { stripePromise } from '../../../../gatsby-browser';
import { PaymentMethods, fetchPaymentMethods, attachPaymentMethood } from '../../../modules/payment_methods_module';
import { Auth } from '../../../modules/auth_module';
import { Org } from '../../../modules/organizations_module';

const cards = {
    amex, visa, discover, mastercard,
}

const SettingsPage = () => {
    const paymentMethods = PaymentMethods.use();
    const auth = Auth.use();
    const org = Org.use();
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        // Block native form submission.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        // Use your card Element with other Stripe.js APIs
        const res = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (res.error) {
            // console.log('[error]', error);
        } else {
            console.log('[PaymentMethod]', res.paymentMethod);
            attachPaymentMethood(auth.activeOrg, { paymentMethod: res.paymentMethod.id })
            console.log("postedsss")
        }
    };

    const isOrgAdmin = () => {
        const member = org.org?.Members.find((member) => member.Uid = auth.user_id);
        console.log(member);
        return member?.Role === "ADMIN";
    }

    useEffect(() => {
        fetchPaymentMethods(auth.activeOrg)
    }, [auth.activeOrg])

    return (
        <AppPageContainer activePage="settings">
            <ToolBar pageName="Settings" />
            { !isOrgAdmin() && (
                <div className="mt-4 border border-gray-200 p-4 shadow-md rounded-lg grid grid-cols-4 gap-4">
                    Only organization admins can edit these settings.
                </div>
            )}
            { isOrgAdmin() && (
            <div className="mt-4 border border-gray-200 p-4 shadow-md rounded-lg grid grid-cols-4 gap-4">
                <div className="col-span-2">
                    <div className="text-lg font-bold mb-2">Add Card</div>
                    <form className="p-2 border border-gray-400" onSubmit={handleSubmit}>
                        <CardElement />
                        <button className="bg-primary rounded py-1 px-2 text-white mt-2" disabled={!stripe} type="submit">Save Card</button>
                    </form>
                </div>
                <div className="col-span-4">
                    <h2 className="text-lg font-bold mb-2">Your saved cards</h2>
                    {!paymentMethods.loading && paymentMethods.paymentMethods?.map((pm, i) => (
                        <div key={`${i}_${pm.Last4}`} className="flex items-center mt-1 text-gray-600">
                            <img className="h-8 mr-2" src={cards[pm.Brand]} />    
                            <code className="mr-2 font-bold">{pm.Last4}</code>
                            <code className="m-0">{pm.ExpMonth}/{pm.ExpYear}</code>
                        </div>
                    ))}
                </div>
            </div>
            )}
            
        </AppPageContainer>
    )
};

const Wrapped = (props) => (
    <Elements stripe={stripePromise}>
        <SettingsPage {...props} />
    </Elements>
)

export default Wrapped;