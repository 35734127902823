import { entity, persistence } from 'simpler-state';
import { Auth } from './auth_module';
import axios from 'axios';

const api = axios.create({
    baseURL: process.env.GATSBY_API_URL ?? "http://localhost:8080/",
    timeout: 6000,
});

export const PaymentMethods = entity({
    loading: false,
    error: false,
    paymentMethods: null,
    errorMsg: null,
}, [
    persistence('paymentMethods', { storage: 'session' })
])

export const fetchPaymentMethods = async (orgId) => {
    PaymentMethods.set({ ...PaymentMethods.get(), loading: true })
    const result = await api.get(`/organizations/${orgId}/PaymentMethods`, { withCredentials: true });
    if (result.status !== 200) {
        PaymentMethods.set({...PaymentMethods.get(), loading: false, error: true, errorMsg: result.status })
    } else {
        PaymentMethods.set({...PaymentMethods.get(), loading: false, paymentMethods: result.data})
    }
}

export const attachPaymentMethood = async (orgId, data) => {
    let response = { error: { error: false, msg: null }, data: null }
    const result = await api.post(`/organizations/${orgId}/PaymentMethods`, data, { withCredentials: true });
    if (result.status !== 200) {
        PaymentMethods.set({...PaymentMethods.get(), loading: false, error: true, errorMsg: result.status })
    } else {
        const d = result.data;
        PaymentMethods.set({...PaymentMethods.get(), loading: false, paymentMethods: result.d})
        return d
    }   
}